import { api } from "./api";

// Auth
export const register = (data) => {
    return api.post("/user/register", data);
};

export const verify = (id, data) => {
    return api.post(`/user/verify/${id}`, data);
};

export const login = (data) => {
    return api.post("/user/login", data);
};

export const forgot = (data) => {
    return api.post("/user/forgetpassword", data);
};

export const reset = (data) => {
    return api.put("/user/resetpassword", data);
};

export const logout = () => {
    return api.get("/user/logout");
};

export const getCurrentUser = () => {
    return api.get("/user/getMyProfile");
};

export const updateProfile = (data) => {
    return api.put("/user/updateProfile", data);
};


// Categories
export const getAllCategories = () => {
    return api.get("/category/getAll");
};

export const getCategoryById = (id) => {
    return api.get(`/category/getCategoryById/${id}`);
};

export const postCategory = (data) => {
    return api.post("/category/create", data);
};

export const updateCategory = (id, data) => {
    return api.put(`category/updateCategory/${id}`, data);
};

export const deleteCategoryById = (id) => {
    return api.delete(`/category/deleteCategory/${id}`);
};

// Ads
export const getAllAds = () => {
    return api.get("/ad/getAll");
};

export const getAdsById = (id) => {
    return api.get(`/ad/getAdById/${id}`);
};

export const postAds = (data) => {
    return api.post("/ad/create", data);
};

export const updateAds = (id, data) => {
    return api.put(`/ad/updateAd/${id}`, data);
};

export const deleteAdsById = (id) => {
    return api.delete(`/ad/deleteAd/${id}`);
};

// News
export const postNewphoto = (data) => {
    return api.post("/news/addphoto", data);
};

export const postNews = (data) => {
    return api.post("/news/createNews", data);
};

export const getNewsById = (id) => {
    return api.get(`/news/getNewsById/${id}`);
};

export const getNewsBySlugId = (id) => {
    return api.get(`/news/getNewsBySlug/${id}`);
};

export const putNews = (id, data) => {
    return api.put(`/news/updateNews/${id}`, data);
};

export const putNewsBySlug = (id, data) => {
    return api.put(`/news/updateNewsBySlug/${id}`, data);
};

export const getAllNews = () => {
    return api.get(`/news/getAllNews`);
};

export const getAllDraftedNews = () => {
    return api.get(`/news/getAllDraftNews`);
};

export const getAllPublishedNews = () => {
    return api.get(`/news/getAllPublishedNews`);
};

export const deleteNewsById = (id) => {
    return api.delete(`/news/deleteNews/${id}`);
};

export const deleteNewsBySlugId = (id) => {
    return api.delete(`/news/deleteNewsBySlug/${id}`);
};

export const newsImageUpload = (formData) => {
    return api.post(`/news/addphoto`, formData);
}

export const newsFileUpload = (formData) => {
    return api.post(`/news/uploadFile`, formData);
}

// Counts
export const getDraftCount = () => {
    return api.get(`/news/getDraftCountThisMonth`);
};

export const getNewsCount = () => {
    return api.get(`/news/getNewsCountThisMonth`);
};

export const getAdsCount = () => {
    return api.get(`/ad/getAdCountThisMonth`);
};

// Publish News
export const putPublishNews = (id) => {
    return api.put(`/news/publishNewsBySlug/${id}`);
}

// Add set

export const postAddSet = (data) => {
    return api.post(`/adset/create`,data);
}

export const putAddSet = (data,id) => {
    return api.put(`/adset/updateAdSet/${id}`,data);
}

export const getAllAddSet = () => {
    return api.get(`/adset/getAll`);
};

export const deleteAddSetById = (id) => {
    return api.delete(`/adset/deleteAdSet/${id}`);
};