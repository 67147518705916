import { Button, Icon, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useNavigate } from 'react-router-dom';
import { forgot } from '../../../Services/AdminServices';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { unsetUser } from '../../../redux/slices/userSlice';

function Forgotpassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");

  useEffect(() => {
    sessionStorage.removeItem("authtoken");
    dispatch(unsetUser());
  }, []);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError(!validateEmail(emailValue));
    setEmailHelperText(validateEmail(emailValue) ? "" : "Please enter the valid email address");
  };

  const validateEmail = (email) => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleForgot = async () => {
    if (!validateEmail(email)) {
      setEmailError(true);
      setEmailHelperText("Please enter the valid email address");
      return;
    }

    let data = {
      email: email,
    }
    await forgot(data).then((res) => {
      toast.success(res?.data?.message);
      setTimeout(() => {
        navigate('/admin/reset', { state: { email: data.email } });
      }, 2000);
    }).catch((err) => { toast.error(err.response.data.message) })
  }

  const forgotButton = {
    backgroundColor: '#575DFB',
    fontSize: 15,
    marginBottom: 3
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '400px', padding: '0 20px' }}>
        <h2 style={{ color: '#575DFB', textAlign: 'center' }}>Forgot Password</h2>
        <h4 style={{ margin: '0px' }}>Email</h4>
        <TextField
          error={emailError}
          helperText={emailHelperText}
          onChange={handleEmailChange}
          value={email}
          placeholder='Email'
          margin="dense"
          variant="outlined"
          fullWidth={true}
          sx={{ mb: 3 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>
                  <EmailOutlinedIcon sx={{ color: '#575DFB' }} />
                </Icon>
              </InputAdornment>
            )
          }}
        />
        <Button
          type='submit'
          style={forgotButton}
          fullWidth={true}
          color='primary'
          variant='contained'
          onClick={handleForgot}
        >
          Forgot Password
        </Button>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },

          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },

          },
        }}
      />
    </div>
  );
}

export default Forgotpassword;
