import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Card, Grid, Icon, InputAdornment, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LayersIcon from '@mui/icons-material/Layers';
import { useNavigate } from 'react-router-dom';
import TitlebarImageList from './TitlebarImageList';
import { deleteAddSetById, getAllAddSet, getAllAds, postAddSet, putAddSet } from '../../../../../Services/AdminServices';
import Loader from '../../../../../Components/Loader/Loader';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function Ads() {
  const navigate = useNavigate();
  const [ads, setAds] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedAds, setSelectedAds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newSetName, setNewSetName] = useState("");
  const [allAddSets, setAllAddSets] = useState([]);
  const [allAddSetSelect, setAllAddSetSelect] = useState("");

  const [addSetDeleteDialog, setAddSetDeleteDialog] = useState(false);
  const [addSetDeleteId, SetAddSetDeleteId] = useState("");

  const getAllAd = async () => {
    setLoading(true);
    try {
      await getAllAds().then((res) => {
        setAds(res?.data?.ads);
        return getAllAddSet();
      }).then((resposne) => {
        setAllAddSets(resposne?.data?.adSets)
      })
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAd();
  }, []);

  const filteredAds = ads && ads.filter(ad =>
    ad?.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAdSelection = (adId) => {
    setSelectedAds(prevSelected =>
      prevSelected.includes(adId)
        ? prevSelected.filter(id => id !== adId)
        : [...prevSelected, adId]
    );
  };

  const handleCreateSet = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewSetName("");
    setSelectedAds([]);
  };

  const [adSetEditStatus, setAdSetEditStatus] = useState("");

  const handleAddSetSelectChange = (value, status) => {
    setAdSetEditStatus(status);
    setAllAddSetSelect(value);
    if (value === "") {
      getAllAd();
      setSelectedAds([]);
    } else {
      let addSet = allAddSets.find((set) => set?._id === value);
      if (addSet) {
        setSelectedAds(addSet?.ads.map(f => f?._id));
      }
    }
  }

  const handleSaveSet = async () => {
    if (newSetName.trim() === "") {
      toast.error("Please enter a name for the set");
      return;
    }

    if (selectedAds.length === 0) {
      toast.error("Please Select Atlease 1 Ad");
      return;
    }

    let data = {
      "name": newSetName,
      "ads": selectedAds,
    }

    await postAddSet(data).then((res) => {
      toast.success(res?.data?.message);
      handleCloseDialog();
      getAllAd();
    })
  };

  const [adSetDialog, setAdSetDialog] = useState(false);
  const adSetEditDIalogOpen = (id) => {
    setAdSetDialog(true);
    let addSet = allAddSets.find((set) => set?._id === id);
    setNewSetName(addSet?.name);
  }
  const adSetEditDIalogClose = () => {
    setAdSetDialog(false);
    setNewSetName('');
    setSelectedAds([]);
    setAllAddSetSelect("");
  }

  const handleSaveSetEdit = async (id) => {
    if (newSetName.trim() === "") {
      toast.error("Please enter a name for the set");
      return;
    }

    if (selectedAds.length === 0) {
      toast.error("Please Select Atlease 1 Ad");
      return;
    }

    let data = {
      "name": newSetName,
      "ads": selectedAds,
    }

    await putAddSet(data, id).then((res) => {
      toast.success(res?.data?.message);
      adSetEditDIalogClose();
      getAllAd();
    })
  };

  // Add Set Delete Function
  const adSetDeleteDialogOpen = (id) => {
    SetAddSetDeleteId(id);
    setAddSetDeleteDialog(true);
  }
  const adSetDeleteDialogClose = () => {
    setAddSetDeleteDialog(false);
    SetAddSetDeleteId("");
  }

  const addSetDeleteClick = async () => {
    await deleteAddSetById(addSetDeleteId).then((res)=>{
      toast.success(res?.data?.message);
      adSetDeleteDialogClose();
      getAllAd();
    }).catch((err)=>{toast.error(err.response.data.message);})
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Ads
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Card sx={{ boxShadow: 'none', borderRadius: 5, p: 2 }}>
            <Box className="category-flex">
              <Box className="section-category-header-flex">
                <div className='category-header-icon'></div>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Ads List
                </Typography>
                <TextField
                  placeholder="Search..."
                  size='small'
                  sx={{ backgroundColor: '#F4F4F4' }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon>
                          <SearchIcon />
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Box className="actions-flex" sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <FormControl sx={{ flexGrow: 0, minWidth: 195 }}>
                  <InputLabel id="type-select-label">Ads Set</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    label="Ads Set"
                    value={allAddSetSelect}
                    onChange={(e) => { handleAddSetSelectChange(e.target.value, "select") }}
                    sx={{
                      '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
                        height: 'auto',
                        minHeight: '1.4375em',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }
                    }}
                  >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {allAddSets.map((set, index) => (
                      <MenuItem value={set?._id} key={index}>{set?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedAds.length !== 0 && adSetEditStatus === "select" ? (
                  <div>
                    <IconButton onClick={() => { adSetEditDIalogOpen(allAddSetSelect) }}><EditIcon color='primary' /></IconButton>
                    <IconButton><DeleteIcon color='error' onClick={() => { adSetDeleteDialogOpen(allAddSetSelect) }} /></IconButton>
                  </div>
                ) : ("")}
                <Button
                  variant='contained'
                  style={{ backgroundColor: "#4caf50", color: "white", borderRadius: 10 }}
                  onClick={handleCreateSet}
                >
                  <LayersIcon />
                  Create Ad Set ({selectedAds.length})
                </Button>
                <Button

                  variant='contained'
                  style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10 }}
                  onClick={() => { navigate('/admin/new-ads') }}
                >
                  <AddIcon sx={{ mr: 1 }} />Create Ad
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {filteredAds && filteredAds.length === 0 ? (
            <Typography textAlign="center" sx={{ fontWeight: 'bold', fontSize: 20 }}>No Records Found...</Typography>
          ) : (
            <TitlebarImageList
              ads={filteredAds}
              getAllAd={getAllAd}
              selectedAds={selectedAds}
              onAdSelection={handleAdSelection}
            />
          )}
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Create New Ad Set</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Set Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newSetName}
            onChange={(e) => setNewSetName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveSet}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={adSetDialog} onClose={adSetEditDIalogClose}>
        <DialogTitle>Edit Ad Set</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Set Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newSetName}
            onChange={(e) => setNewSetName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={adSetEditDIalogClose}>Cancel</Button>
          <Button onClick={() => { handleSaveSetEdit(allAddSetSelect) }}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={addSetDeleteDialog}
        onClose={() => adSetDeleteDialogClose()}
      >
        <DialogTitle>
          Are you sure you want to remove this Ad Set?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", flexDirection: 'row', justifyContent: "center", gap: 2, flexWrap: 'wrap' }}>
            <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { addSetDeleteClick() }}>YES</Button>
            <Button color='error' sx={{ fontSize: 15 }} onClick={() => { adSetDeleteDialogClose() }} >NO</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Ads;