import { Button, Icon, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { reset } from '../../../Services/AdminServices';
import { useDispatch } from 'react-redux';
import { unsetUser } from '../../../redux/slices/userSlice';
import toast, { Toaster } from 'react-hot-toast';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const [email, setEmail] = useState(state && state.email || "");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordHelperText, setPasswordHelperText] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [otpHelperText, setOtpHelperText] = useState("");

    // Password Show & Hide
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        sessionStorage.removeItem("authtoken");
        dispatch(unsetUser());
    }, []);

    const handlePasswordChange = (e) => {
        const passwordValue = e.target.value;
        setPassword(passwordValue);
        setPasswordError(!validatePassword(passwordValue));
        setPasswordHelperText(validatePassword(passwordValue) ? "" : "Password must be at least 8 characters long");
    };

    const validatePassword = (password) => {
        // Password validation logic, e.g., minimum length
        return password.length >= 8;
    };

    const handleOtpChange = (e) => {
        const otpValue = e.target.value;
        setOtp(otpValue);
        setOtpError(otpValue === "");
        setOtpHelperText(otpValue === "" ? "Please enter the OTP" : "");
    };

    const handleReset = async () => {
        if (otp === "") {
            setOtpError(true);
            setOtpHelperText("Please enter the OTP");
            return;
        }

        if (!validatePassword(password)) {
            setPasswordError(true);
            setPasswordHelperText("Password must be at least 8 characters long");
            return;
        }

        let data = {
            email: email,
            otp: otp,
            newPassword: password
        }
        await reset(data).then((res) => {
            toast.success(res?.data?.message);
            setTimeout(() => {
                navigate('/admin/login');
            }, 2000);
        }).catch((err) => { toast.error(err.response.data.message) })
    }

    const resetButton = {
        backgroundColor: '#575DFB',
        fontSize: 15,
        marginBottom: 3
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '400px', padding: '0 20px' }}>
                <h2 style={{ color: '#575DFB', textAlign: 'center' }}>Reset Password</h2>
                <h4 style={{ margin: '0px' }}>Email</h4>
                <TextField
                    disabled={true}
                    value={email}
                    placeholder='Email'
                    margin="dense"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mb: 3 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon>
                                    <EmailOutlinedIcon sx={{ color: '#575DFB' }} />
                                </Icon>
                            </InputAdornment>
                        )
                    }}
                />
                <h4 style={{ margin: '0px' }}>Enter OTP</h4>
                <TextField
                    error={otpError}
                    helperText={otpHelperText}
                    onChange={handleOtpChange}
                    value={otp}
                    placeholder='Enter OTP'
                    margin="dense"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mb: 3 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon>
                                    <EmailOutlinedIcon sx={{ color: '#575DFB' }} />
                                </Icon>
                            </InputAdornment>
                        )
                    }}
                />
                <h4 style={{ margin: '0px' }}>Enter New Password</h4>
                <TextField
                    error={passwordError}
                    helperText={passwordHelperText}
                    onChange={handlePasswordChange}
                    value={password}
                    placeholder='Enter New Password'
                    margin="dense"
                    variant="outlined"
                    fullWidth={true}
                    sx={{ mb: 3 }}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleClickShowPassword}>
                                    {showPassword ? <VisibilityIcon sx={{ color: '#575DFB' }} /> : <VisibilityOffIcon sx={{ color: '#575DFB' }} />}
                                </Icon>
                            </InputAdornment>
                        )
                    }}
                />
                <Button
                    type='submit'
                    style={resetButton}
                    fullWidth={true}
                    color='primary'
                    variant='contained'
                    onClick={handleReset}
                >
                    Reset Password
                </Button>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    success: {
                        style: {
                            background: 'rgb(46, 125, 50)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(46, 125, 50)',
                            secondary: 'white',
                        },

                    },
                    error: {
                        style: {
                            background: 'rgb(211, 47, 47)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(211, 47, 47)',
                            secondary: 'white',
                        },

                    },
                }}
            />
        </div>
    );
}

export default Login;
