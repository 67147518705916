import { Box, CircularProgress } from '@mui/material'
import React from 'react'

function Loader() {
    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress style={{ height: 70, width: 70, color: '#176B87' }} />
        </Box>
    )
}

export default Loader