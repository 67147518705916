import React, { useEffect, useState } from 'react'
import { Box, Button, Card, TextField, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { getCurrentUser, updateProfile } from '../../../Services/AdminServices';
import Loader from '../../../Components/Loader/Loader';

const card = styled(Card)(({ theme }) => ({
    backgroundColor: "#fff",
    boxShadow: 'none',
    marginBottom: 20,
    width: '70%',
    '@media (max-width: 1200px)': {
        width: '70%', // Adjust width for desktop
    },

    '@media (max-width: 992px)': {
        width: '100%', // Adjust width for tablets
    },
    '@media (max-width: 768px)': {
        width: '100%',// Adjust width for Mobiles
    },
}));

function ProfileSettings() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);

    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");

    // Loader
    const [loading, setLoading] = useState(false);

    const getProfile = () => {
        setLoading(true);
        getCurrentUser().then((res) => {
            let data = res?.data?.data;
            setFirstName(data?.firstname);
            setLastName(data?.lastname);
            setEmail(data?.email);
            setContact(data?.contact);
            setUser(data);
            setLoading(false);
        }).catch((err) => { console.log(err); setLoading(false); })
    }

    useEffect(() => {
        getProfile();
    }, []);

    const saveClick = async () => {
        let error = false;
        if (firstName === "") {
            setFirstNameError(true);
            error = true;
        } else {
            setFirstNameError(false);
        }
        if (lastName === "") {
            setLastNameError(true);
            error = true;
        } else {
            setLastNameError(false);
        }
        if (error) return;

        setLoading(true);
        let data = {
            firstname: firstName,
            lastname: lastName
        }
        await updateProfile(data).then((res) => {
            toast.success('Profile Updated Successfully');
            getProfile();
            setLoading(false);
        }).catch((err) => { setLoading(false); toast.error(err.response.data.message) })
    }

    const updateProfilePicture = async (e) => {
        setLoading(true);
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);
        await updateProfile(formData).then((res) => {
            toast.success('Profile Updated Successfully');
            getProfile();
            setLoading(false);
        }).catch((err) => { setLoading(false); toast.error(err.response.data.message) })
    }

    if (loading) {
        return <Loader />
    }

    return (
        <div>
            <Box>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Profile Settings
                </Typography>
            </Box>
            <Box component={card}>
                <Box className="section-new-category-add-information-header-flex">
                    <div className='new-category-add-information-icon'></div>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Profile Information
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', padding: '20px', alignItems: 'center' }}>
                    <Avatar
                        alt="profile"
                        src={user?.avatar?.url}
                        sx={{ width: 100, height: 100 }}
                    />
                    <Box>
                        <label htmlFor="profileUpload">
                            <input
                                id="profileUpload"
                                type="file"
                                accept="image/*"
                                onChange={(e) => { updateProfilePicture(e) }}
                                style={{ display: 'none' }}
                            />
                            <Button
                                component="span"
                                variant="contained"
                                style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10, fontSize: 15 }}
                            >
                                Update Profile Picture
                            </Button>
                        </label>
                    </Box>
                </Box>
                <Box className="new-category-padding">
                    <TextField
                        error={firstNameError}
                        id="outlined-basic"
                        label="First Name"
                        placeholder='First Name'
                        variant="outlined"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        required={true}
                        value={firstName}
                        onChange={(e) => { setFirstName(e.target.value) }} />
                    <TextField
                        error={lastNameError}
                        id="outlined-basic"
                        label="Last Name"
                        placeholder='Last Name'
                        variant="outlined"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        required={true}
                        value={lastName}
                        onChange={(e) => { setLastName(e.target.value) }} />
                    <TextField
                        disabled={true}
                        id="outlined-basic"
                        label="Email"
                        placeholder='Email'
                        variant="outlined"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        required={true}
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }} />
                    <TextField
                        disabled={true}
                        id="outlined-basic"
                        label="Contact"
                        placeholder='Contact'
                        variant="outlined"
                        sx={{ mb: 5 }}
                        fullWidth={true}
                        required={true}
                        value={contact}
                        onChange={(e) => { setContact(e.target.value) }} />
                </Box>
                <Box className="new-category-padding">
                    <Button
                        variant='contained'
                        style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10, fontSize: 15 }}
                        onClick={() => { saveClick() }}
                    >Save</Button>
                </Box>
            </Box>
            <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                    success: {
                        style: {
                            background: 'rgb(46, 125, 50)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(46, 125, 50)',
                            secondary: 'white',
                        },

                    },
                    error: {
                        style: {
                            background: 'rgb(211, 47, 47)',
                            color: 'white'
                        },
                        iconTheme: {
                            primary: 'rgb(211, 47, 47)',
                            secondary: 'white',
                        },

                    },
                }}
            />
        </div>
    )
}

export default ProfileSettings