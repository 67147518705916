import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Card, Dialog, DialogContent, DialogTitle, FormControl, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { deleteCategoryById, getAllCategories } from '../../../Services/AdminServices';
import Loader from '../../../Components/Loader/Loader';
import toast, { Toaster } from 'react-hot-toast';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

function Category({ categories, setCategories }) {
  const navigate = useNavigate();
  // const [categories, setCategories] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const [loading, setLoading] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [catId, setCatId] = useState("");

  const deleteDialogOpen = (id) => {
    setCatId(id);
    setDeleteDialog(true);
  }
  const deleteDialogCancel = () => {
    setCatId("");
    setDeleteDialog(false);
  }

  const getCategories = async () => {
    setLoading(true);
    await getAllCategories()
      .then((res) => {
        let response = res?.data?.categories;
        setCategories(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  
  const filteredCategories = categories
    ? categories.filter((cat) =>
      cat.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : [];

  const deleteCategory = (id) => {
    deleteCategoryById(id).then((res) => {
      toast.success(res?.data?.message);
      setTimeout(() => {
        getCategories();
        deleteDialogCancel();
      }, 2000);
    }).catch((err) => { toast.error(err.response.data.message) })
  };

  const editCategory = (id) => {
    navigate(`/admin/edit-category/${id}`)
  }

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCategories.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <Box>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Category
        </Typography>
      </Box>
      <Box>
        <TableContainer component={Item}>
          <Box className="category-flex">
            <Box className="section-category-header-flex">
              <div className='category-header-icon'></div>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Category List
              </Typography>
              <TextField
                placeholder="Search..."
                size='small'
                sx={{ backgroundColor: '#F4F4F4' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>
                        <SearchIcon />
                      </Icon>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box className="actions-flex">
              <Box>
                <Button
                  variant='contained'
                  style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10 }}
                  onClick={() => { navigate(`/admin/new-category`) }}
                ><AddIcon />Create</Button>
              </Box>
            </Box>
          </Box>
          <Table sx={{ minWidth: 320 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"><span className='table-header'>Action</span></TableCell>
                <TableCell align="left"><span className='table-header'>Category</span></TableCell>
                <TableCell align="left"><span className='table-header'>Type</span></TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center" sx={{ fontWeight: 'bold', fontSize: 20 }}>No Records Found...</TableCell>
                </TableRow>
              ) : (
                currentItems && currentItems.map((cat, index) => (

                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: 0 }}
                    key={index}
                  >
                    <TableCell align="left">
                      <IconButton title="Edit Category" onClick={() => { editCategory(cat?._id) }}>
                        <EditIcon sx={{ cursor: "pointer" }} />
                      </IconButton>
                      <IconButton title="Delete Category" onClick={() => { deleteDialogOpen(cat?._id) }}>
                        <DeleteIcon sx={{ cursor: 'pointer' }} />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left"><span style={{ fontSize: 17, fontWeight: 'bold' }}>{cat?.categoryName}</span></TableCell>
                    <TableCell align="left"><span style={{ fontWeight: 600 }}>{cat?.type}</span></TableCell>

                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
            <Pagination
              count={Math.ceil(filteredCategories.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size="medium"
            />
          </div>
        </TableContainer>
      </Box >
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={deleteDialog}
        onClose={() => deleteDialogCancel()}
      >
        <DialogTitle>
          Are you sure you want to remove this category?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center", gap: 2, flexDirection: 'wrap' }}>
            <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { deleteCategory(catId) }}>YES</Button>
            <Button color='error' sx={{ fontSize: 15 }} onClick={deleteDialogCancel} >NO</Button>
          </div>
        </DialogContent>
      </Dialog>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },

          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },

          },
        }}
      />
    </div >
  )
}

export default Category;