import React, { useEffect } from 'react'

function AboutUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
  }, []);
  return (
    <div style={{ margin: 20 }}>
      <h2>About Us</h2>
      <p className='footer-text'>Welcome to OnlineGoodNews, your premier Christian online newspaper dedicated to spreading hope, inspiration, and positive stories from around the world. Based in Kottayam, Kerala, India, we are committed to bringing you uplifting news and faith-driven content that reflects the core values of Christianity.</p>
      <p className='footer-text'>Our mission is to provide a platform where believers and seekers alike can find stories of faith, hope, and love. We aim to highlight the good news happening within communities and beyond, offering a beacon of light in a world that often focuses on the negative.</p>
      <h3>Our Team</h3>
      <p className='footer-text'>OnlineGoodNews is powered by a passionate and dedicated team of individuals who believe in the power of good news and positive change. Our administrative team includes:</p>
      <ul>
        <li> <p className='footer-text'>C V Mathew</p></li>
        <li> <p className='footer-text'>T M Mathew</p></li>
        <li> <p className='footer-text'>Shaji Manlyat</p></li>
        <li> <p className='footer-text'>Wesly Mathew</p></li>
        <li> <p className='footer-text'>Saji</p></li>
        <li> <p className='footer-text'>Jessy Shajan</p></li>
        <li> <p className='footer-text'>Finny Korah</p></li>
      </ul>
      <h3>Contact Us</h3>
      <p className='footer-text'>We value your feedback and are here to assist you with any questions or inquiries. Feel free to reach out to us through the following contact details:</p>
      <p className='footer-text'><strong>Address:</strong> ONLINEGOODNEWS.COM, Kottayam 686 004, Kerala, India</p>
      <p className='footer-text'><strong>Phone:</strong></p>
      <ul>
        <li> <p className='footer-text'>India: +91 94473 72726 / +91 99462 05422 / +91 94005 20909</p></li>
        <li> <p className='footer-text'>USA: +1 (469) 573 0264</p></li>
        <li> <p className='footer-text'>UK: +44 7951 963062</p></li>
        <li> <p className='footer-text'>Australia: +61 490043301</p></li>
        <li> <p className='footer-text'>Gulf: +971 50 354 0676</p></li>
      </ul>
      <p className='footer-text'><strong>Email:</strong> <a href="mailto:info@onlinegoodnews.com">info@onlinegoodnews.com</a></p>
    </div>
  )
}

export default AboutUs;