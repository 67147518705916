import React, { useEffect } from 'react'

function Topics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ margin: 20 }}>
      <h2>Topics</h2>
      <ul>
        <li> <p className='footer-text'>Kidz</p></li>
        <li> <p className='footer-text'>Youth</p></li>
        <li> <p className='footer-text'>Women</p></li>
        <li> <p className='footer-text'>Success stories</p></li>
      </ul>
    </div>
  )
}

export default Topics;