import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Dashboard from '../Admin/Pages/Dashboard/Dashboard';
import Category from '../Admin/Pages/category/Category';
import Ads from '../Admin/Pages/Ads/Ads/TitlebarImageList/Ads';
import NewAds from '../Admin/Pages/Ads/Ads/TitlebarImageList/NewAds';
import NewCategory from '../Admin/Pages/category/NewCategory';
import Login from '../Admin/Pages/Auth/Login';
import AuthShield from '../shields/AuthShield';
import Register from '../Admin/Pages/Auth/Register';
import Verify from '../Admin/Pages/Auth/Verify';
import Forgotpassword from '../Admin/Pages/Auth/Forgotpassword';
import Reset from '../Admin/Pages/Auth/reset';
import NewNews from '../Admin/Pages/News/NewNews';
import EditNews from '../Admin/Pages/News/EditNews';
import ProfileSettings from '../Admin/Pages/Auth/ProfileSettings';
import Uploads from '../Admin/Pages/Uploads/Uploads';
import DraftedNews from '../Admin/Pages/News/DraftedNews';
import PublishedNews from '../Admin/Pages/News/PublishedNews';

function AdminRoutePages({ categories, setCategories }) {
    return (
        <Routes>
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/register" element={<Register />} />
            <Route path="/admin/verify/:id" element={<Verify />} />
            <Route path="/admin/forgot" element={<Forgotpassword />} />
            <Route path="/admin/reset" element={<Reset />} />
            <Route
                path="/admin"
                element={
                    <AuthShield>
                        <Outlet />
                    </AuthShield>
                }
            >
                <Route path="profile-settings" element={<ProfileSettings />} />
                <Route path="dashboard" element={<Dashboard categories={categories} />} />
                <Route path="category" element={<Category categories={categories} setCategories={setCategories} />} />
                <Route path="new-category" element={<NewCategory categories={categories} setCategories={setCategories} />} />
                <Route path="edit-category/:catId" element={<NewCategory />} />
                <Route path="drafted-news" element={<DraftedNews categories={categories} />} />
                <Route path="published-news" element={<PublishedNews categories={categories} />} />
                <Route path="new-news" element={<NewNews categories={categories} />} />
                <Route path="edit-news/:newsId" element={<EditNews categories={categories} />} />
                <Route path="ads" element={<Ads />} />
                <Route path="new-ads" element={<NewAds />} />
                <Route path="new-ads/:adsId" element={<NewAds />} />
                <Route path="uploads" element={<Uploads />} />
            </Route>
        </Routes>
    );
}

export default AdminRoutePages;
